import React from "react"
import classNames from "classnames"
import { makeStyles } from "@material-ui/core/styles"

import Header from "../Header/Header"
import HeaderLinks from "../HeaderLinks/HeaderLinks"
import Parallax from "../Parallax/Parallax"
import GridContainer from "../Custom/GridContainer/GridContainer"
import GridItem from "../Custom/GridItem/GridItem"
import GlobalPackages from "../Packages/GlobalPackages"
import LocalPackages from "../Packages/LocalPackages"
import Footer from "../Footer/Footer"

import styles from "./LandingPageStyle"
import Logo from "../../assets/logo.png"

const dashboardRoutes = []
const useStyles = makeStyles(styles)

export default function LandingPage(props) {
  const classes = useStyles()
  const { ...rest } = props
  const { setLoading } = props
  return (
    <div>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Receivers System"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white",
        }}
        {...rest}
      />
      <Parallax filter image={require("../../assets/landing-bg.jpg")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <h1 className={classes.title}>Receivers System</h1>
              <h4>
                Receivers System is an online upgrading solution for global and
                local receivers, you can buy card for global packages such as
                Funcam, Gshare, Forever, and Local ones such as Mixmedia, HDBox,
                Online TV and IP TV.
              </h4>
              <br />
              <img src={Logo} alt="logo" height="160px" width="160px"></img>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <GlobalPackages setLoading={setLoading} />
          <LocalPackages setLoading={setLoading} />
        </div>
        <Footer></Footer>
      </div>
    </div>
  )
}
