import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"

// core components
import GridContainer from "../Custom/GridContainer/GridContainer.js"
import GridItem from "../Custom/GridItem/GridItem.js"
import Box from "@material-ui/core/Box"
import { Link } from "react-router-dom"

import styles from "./FooterStyle"
import Copyright from "./Copyright"

import Tooltip from "@material-ui/core/Tooltip"
// import Button from "../Custom/Button/Button"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles(styles)

export default function Footer() {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <div>
        <Typography
          style={{
            fontSize: "22px",
            color: "white",
            fontWeight: "bold",
            paddingBottom: "10px",
          }}
        >
          Contact Us
        </Typography>

        <Typography style={{ color: "white" }}>
          Address: Iraq - Sulaymaniah
        </Typography>
        <Typography style={{ color: "white" }}>
          <a
            href="tel:+9647503659560"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Phone: +964 770 155 5809{" "}
            <Tooltip
              id="whatsapp-tooltip"
              title="Connect with us on WhatsApp"
              placement={window.innerWidth > 959 ? "top" : "left"}
              classes={{ tooltip: classes.tooltip }}
            >
              <i className={classes.socialIcons + " fab fa-whatsapp"} />
            </Tooltip>
          </a>
        </Typography>
        <Typography style={{ color: "white" }}>
          <a
            href="mailto:receiverssystem@gmail.com"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            Email: receiverssystem@gmail.com
          </a>
        </Typography>
      </div>
      {/* <div>
        <Tooltip
          id="facebook-tooltip"
          title="Follow us on facebook"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.facebook.com"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-facebook"} />
          </Button>
        </Tooltip>

        <Tooltip
          id="instagram-tooltip"
          title="Follow us on instagram"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.instagram.com"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-instagram"} />
          </Button>
        </Tooltip>
      </div> */}
      <Box pt={4} style={{ marginBottom: "30px" }}>
        <Copyright />
      </Box>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={4}>
          <h2 className={classes.title}>Receivers System</h2>
          <hr className={classes.line}></hr>
          <h4 className={classes.description}>
            Receivers System is an online upgrading solution for global and
            local receivers, you can buy card for global packages such as
            Funcam, Gshare, Forever, and Local ones such as Mixmedia, HDBox,
            Online TV and IP TV.
          </h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <h2 className={classes.title}>Global Packages</h2>
          <hr className={classes.line}></hr>
          <Link
            to="/global/gshare"
            color="transparent"
            className={classes.navLink}
          >
            <h4 className={classes.description}>GShare</h4>
          </Link>
          <Link
            to="/global/forever"
            color="transparent"
            className={classes.navLink}
          >
            <h4 className={classes.description}>Forever</h4>
          </Link>
          <Link
            to="/global/funcam"
            color="transparent"
            className={classes.navLink}
          >
            <h4 className={classes.description}>Funcam</h4>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <h2 className={classes.title}>Local Packages</h2>
          <hr className={classes.line}></hr>
          <Link to="/local" color="transparent" className={classes.navLink}>
            <h4 className={classes.description}>Mixmedia</h4>
          </Link>
          <Link to="/local" color="transparent" className={classes.navLink}>
            <h4 className={classes.description}>HDBox</h4>
          </Link>
          <Link to="/local" color="transparent" className={classes.navLink}>
            <h4 className={classes.description}>OnlineTV</h4>
          </Link>
          <Link to="/local" color="transparent" className={classes.navLink}>
            <h4 className={classes.description}>IPTV</h4>
          </Link>
        </GridItem>
      </GridContainer>
    </div>
  )
}
