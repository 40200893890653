import {
  title,
  container,
  accentColor,
  primaryColor,
} from "../Styles/rootStyle"

const packagesStyle = (theme) => ({
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    width: "100%",
    marginLeft: "-15px",
  },
  description: {
    color: "#999",
    width: "100%",
    marginLeft: "-15px",
    marginBottom: "40px",
  },
  packageHeaderPrimary: {
    width: "100%",
    color: "white",
    backgroundColor: primaryColor,
    borderTopLeftRadius: "0.2rem",
    borderTopRightRadius: "0.2rem",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  packageHeaderAccent: {
    width: "100%",
    color: "white",
    backgroundColor: accentColor,
    borderTopLeftRadius: "0.2rem",
    borderTopRightRadius: "0.2rem",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  packageContent: {
    bottom: 0,
    width: "100%",
    color: "black",
    backgroundColor: "white",
    height: "60px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContent: {
    color: "black",
    backgroundColor: "white",
    height: "60px",
    marginBottom: "10px",
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container,
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  navLink: {
    color: "inherit",
    position: "relative",
    padding: "0.9375rem",
    fontWeight: "400",
    fontSize: "12px",
    textTransform: "uppercase",
    borderRadius: "3px",
    lineHeight: "20px",
    textDecoration: "none",
    margin: "0px",
    display: "inline-flex",
    "&:hover,&:focus": {
      color: "inherit",
      background: "rgba(200, 200, 200, 0.2)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)",
      marginLeft: "15px",
      marginBottom: "8px",
      marginTop: "8px",
      textAlign: "left",
      "& > span:first-child": {
        justifyContent: "flex-start",
      },
    },
  },
})

export default packagesStyle
