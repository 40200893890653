import React from "react"
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "react-router-dom"

import MixmediaLogo from "../../assets/mixmedia_logo.png"
import HdboxLogo from "../../assets/hdbox_logo.png"
import IptvLogo from "../../assets/iptv_logo.png"
import OnlinetvLogo from "../../assets/onlinetv_logo.png"

// core components
import GridContainer from "../Custom/GridContainer/GridContainer.js"
import GridItem from "../Custom/GridItem/GridItem.js"

import styles from "./packagesStyle"
import { Paper, Typography } from "@material-ui/core"
import Button from "../Custom/Button/Button"

const LocalPackagesList = [
  { name: "Mixmedia", image: MixmediaLogo },
  { name: "HD Box", image: HdboxLogo },
  { name: "IPTV Mediastar", image: IptvLogo },
  { name: "Online TV IStar", image: OnlinetvLogo },
]

const useStyles = makeStyles(styles)

export default function LocalPackages() {
  const classes = useStyles()

  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <Typography variant="h4" className={classes.title}>
            Extra Packages
          </Typography>
          <Typography variant="body1" className={classes.description}>
            List of popular extra packages
          </Typography>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer style={{ width: "100%" }}>
          {LocalPackagesList.map((item, index) => {
            return (
              <GridItem xs={12} sm={12} md={3} key={index}>
                <div
                  className={
                    index === 0 || index === LocalPackagesList.length - 1
                      ? classes.packageHeaderAccent
                      : classes.packageHeaderPrimary
                  }
                >
                  <Typography variant="h5">{item.name}</Typography>
                </div>
                <Paper elevation={1}>
                  <img
                    src={item.image}
                    alt="img"
                    width="80%"
                    height="80%"
                    style={{ padding: "10%" }}
                  />
                </Paper>
              </GridItem>
            )
          })}
          <GridItem xs={12}>
            <Link to="/local" color="transparent" className={classes.navLink}>
              <Button color="primary" size="sm" rel="noopener noreferrer">
                More Local Packages Info
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
